<template>
  <g fill="none" fill-rule="evenodd">
    <g stroke="#E5472D">
      <g transform="translate(-135 -188) translate(137.5 190)">
        <g stroke-linecap="round" stroke-linejoin="round" stroke-width="4.773">
          <path d="M29.83 29.83L0 0M29.83 0L0 29.83" transform="translate(35 35)" />
        </g>
        <circle cx="50" cy="50" r="50" stroke-width="3" />
      </g>
    </g>
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
